import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import SectionHeading from '../components/SectionHeading'

//images
import aboutImg3 from '../assets/img/about/ldb-video.jpg'
// import aboutImg4 from '../assets/img/about/01-about us@2x.jpg'
// import aboutImg5 from '../assets/img/about/02-about us@2x.jpg'
import video from '../assets/videos/LBD-About-Us.mp4'

class About extends React.Component{

    EmbedVideo = () => {
      return (
          <div  className="ldb_video" dangerouslySetInnerHTML={{ __html: `
            <video title="ldbYoutubeVideo" width="560" height="315" poster="${aboutImg3}" autoplay loop playsinline controls>
              <source src=${video} type="video/mp4" />

            Your browser does not support the video tag.
            </video>
          ` }}></div>
      )
    }

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/about/#webpage",
                "url": "https://www.ldbconstruction.com/about/",
                "name": "About - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2014-02-26T15:20:19+00:00",
                "dateModified": "2020-12-20T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/about/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>About - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is a luxury residential building, refurbishment and maintenance company specialising in design and build solutions for exclusive clients in prime areas of central London."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <TopSection 
                        heading="about us"
                        text="LDB Construction is a luxury residential building, refurbishment and maintenance company specialising in design and build solutions for exclusive clients in prime areas of central London."
                    />
                    <section className="ldb_about-section">
                      <Container>
                           {/* <Row> 
                              <Col xs={12}> */}
                                  {/* <div className="ldb_video"> */}
                                      {/* <iframe title="ldbYoutubeVideo" width="560" height="315" src="https://www.youtube.com/embed/9PW8y3wIt-o" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>     */}
                                      {/* <video title="ldbYoutubeVideo" width="560" height="315" muted autoplay loop playsinline>
                                        <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                      </video>  */}
                                      {/* {EmbedVideo} */}
                                  {/* </div>  */}
                                {/*  {this.EmbedVideo()}
                              </Col>
                          </Row> */}
                          <Row className="ldb_about-bodycopy">
                              <Col xs={12} md={6}>
                                  {/*<SectionHeading text="renowned for our quality and attention to detail" />*/}
                                  <SectionHeading text="Founded in 2007, LDB has become the leader in super prime london construction setting the standard for quality, attention to detail and responsible and safe working practices." />
                              </Col>
                              <Col xs={12} md={6}>
                                  <p>We have a dynamic, professional and experienced management team working alongside handpicked craftsmen who offer a highly customisable service of impeccable workmanship. We are deeply committed to the business of building, whether it is a penthouse apartment or a listed building. We also offer a comprehensive maintenance service for the owners of properties we have completed. In addition, we undertake more specialised and unique projects through our Special Projects division and are proud to offer the same professional service as we do through the construction arm.</p>

                                  <p className="mb-4">Our business is built on teamwork and we are renowned for our quality and attention to detail. We are also uncompromising about working safely. We insist on in-depth discussions with our clients and their design teams and will take the time to fully understand their objectives and circumstances. This enables us to deliver build solutions that are affordable and sustainable. As a result, we have formed lasting relationships with our clients who value our integrity and accountability.</p>
                                  <div className="ldb_horline"></div>
                              </Col>
                              
                          </Row>
                          {/* <Row>
                              <Col xs={12} className="ldb_about-images">
                                <img src={aboutImg4} alt="About LDB 1" />
                              </Col>
                              <Col xs={12} className="ldb_about-images">
                                <img src={aboutImg5} alt="About LDB 2" />
                              </Col>
                          </Row> */}
                          <Row>
                              <Col xs={12}>
                                  {this.EmbedVideo()}
                              </Col>
                          </Row>
                      </Container>
                  </section> 
                    
                </Nav>      
                <Footer />
            </React.Fragment>
        )
    }
}

export default About