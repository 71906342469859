import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import {
    Container,
    Row
} from 'react-bootstrap'

import {
    fetchProjects
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import ProjectItem from '../components/ProjectItem'
import TopSection from '../components/TopSection'
import {Helmet} from "react-helmet";

class Projects extends React.Component{

    componentDidMount(){
        this.props.fetchProjects()
    }

    renderList(){
        return this.props.projects.map((item, index) => {
            return (
                <ProjectItem 
                    key={index}
                    image={`${BASE_URL}${item.image}`}
                    link={`/projects/${item.search_name}`}
                    title={item.title}
                />
            )
        })
    }

    render(){
        if(!this.props.projects) {return <></>}

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "CollectionPage",
                "@id": "https://www.ldbconstruction.com/projects/#webpage",
                "url": "https://www.ldbconstruction.com/projects/",
                "name": "Projects | LDB Construction",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "inLanguage": "en-US"
              }
            ]
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Projects - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is unique in London in that we offer a complete turnkey service and will work with our clients from initial consultation, to the delivery and installation of furniture, and provide maintenance support after the project is complete. Throughout the project we oversee and manage every detail of the construction and refurbishment so that every inch exactly meets our client’s exacting standards."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav> 
                    <TopSection 
                        heading="projects"
                        text="LDB Construction is unique in London in that we offer a complete turnkey service and will work with our clients from initial consultation, to the delivery and installation of furniture, and provide maintenance support after the project is complete. Throughout the project we oversee and manage every detail of the construction and refurbishment so that every inch exactly meets our client’s exacting standards."
                    />
                    <section className="ldb_products-section">
                        <Container>
                            <Row>
                                {this.renderList()}
                            </Row>
                        </Container>
                    </section>
                </Nav>            
                <Footer />    
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.projects
    }
}

export default connect(mapStateToProps, {
    fetchProjects
})(Projects)