import React from 'react'
import ldbLogo from '../assets/img/ldb-logo.svg'
import ldbLogoSustainability from '../assets/img/ldb-logo-sustainability.svg'
import HamburgerIcon from './HamburgerIcon'
import MobileMenu from './MobileMenu'
import WidescreenNav from './WidescreenNav'
import SocialMediaNav from './SocialMediaNav'
import {
    Link
} from 'react-router-dom'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

class Nav extends React.Component{

    state = {
        menuOpen: false
    }

    onOpenMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    renderLdbLogo = () => {
        if (window.location.pathname === '/sustainability') {
            return <img className="ldb_logo" src={ldbLogoSustainability} alt="LDB Construction UK Ltd" />;
        } else {
            return <img className="ldb_logo" src={ldbLogo} alt="LDB Construction UK Ltd" />;
        }
    }

    render(){
        return(
            <React.Fragment>
                <header>
                    <Container>
                        <Row>
                            <Col className="ldb_logo-col" lg={2} xs={6}>
                                <div className="ldb_left-panel">
                                    <Link to="/" title="Go to homepage">
                                        {this.renderLdbLogo()}
                                    </Link>
                                </div>
                            </Col>
                            
                            <Col className="ldb_mobile-col" xs={6}>
                                <HamburgerIcon onClick={this.onOpenMenu} menuOpen={this.state.menuOpen} />
                                <MobileMenu menuOpen={this.state.menuOpen} />
                            </Col>
                            <Col className="ldb_widescreen-col" lg={8}>
                                <WidescreenNav />
                            </Col>
                            <Col className="ldb_social-col" lg={2}>
                                <SocialMediaNav />
                            </Col>
                        </Row>
                    
                    </Container>
                    
                </header>

                <main>
                    {this.props.children}
                </main> 
            </React.Fragment>
        )
    }
}

export default Nav