import React from 'react'
import CopywrightText from './CopywrightText'
import SocialMediaNav from './SocialMediaNav'
import NavLinks from './NavLinks'

class MobileMenu extends React.Component{
    render(){
        return(
            <div className={`ldb_mobile-menu ${this.props.menuOpen ? 'open' : ''}`} onClick={this.props.onClick}>              
                <ul className="ldb_list-menu">
                    <NavLinks />
                </ul>
                <SocialMediaNav />
                <CopywrightText />
            </div>
        )
    }
}

export default MobileMenu