import React from 'react'
import {
    NavLink
} from 'react-router-dom'

class NavLinks extends React.Component{
    render(){
        return(
            <React.Fragment>
                <li>
                    <NavLink to="/projects" activeClassName="active">Projects</NavLink>
                </li>
                <li>
                    <NavLink to="/about" activeClassName="active">About</NavLink>
                </li>
                <li>
                    <NavLink to="/team" activeClassName="active">Team</NavLink>
                </li>
                <li>
                    <NavLink to="/aftercare" activeClassName="active">Aftercare</NavLink>
                </li>
                <li>
                    <NavLink to="/wellness" activeClassName="active">Wellness</NavLink>
                </li>
                <li>
                    <NavLink to="/sustainability" activeClassName="active">Sustainability</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
                </li>
                
            </React.Fragment>
        )
    }
}

export default NavLinks