import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import projectsReducer from './projectsReducer'
import projectReducer from './projectReducer'
import teamReducer from './teamReducer'
import memberReducer from './memberReducer'
import authReducer from './authReducer'
import privateProjectsReducer from './privateProjectsReducer'
import privateProjectReducer from './privateProjectReducer'
import entryReducer from './entryReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    projects: projectsReducer,
    project: projectReducer,
    privateProjects: privateProjectsReducer,
    privateProject: privateProjectReducer,
    team: teamReducer,
    member: memberReducer,
    auth: authReducer,
    entry: entryReducer,
    form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)