import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import PageHeading from './PageHeading'
import PageText from './PageText'
import BackOneLevel from './BackOneLevel'

class TopSection extends React.Component{
    render(){
        return(
            <section className="ldb_top-section" style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : '#FFFFF'}}>
                <Container>
                    <Row>
                        <Col sm={12} md={12} xl={6}>
                            
                            <PageHeading text={this.props.heading} />

                            {
                                this.props.back && (
                                    <BackOneLevel link={this.props.back} text={this.props.backText ? this.props.backText : null} />
                                )
                            }
                            
                        </Col>
                        {/* <Col sm={12} md={12} xl={6}>
                            <PageText
                                text={this.props.text}
                            />
                        </Col> */}
                        {
                            this.props.children && (
                                <Col sm={12} md={12} xl={6}>
                                    {this.props.children}
                                </Col>
                            )
                        }
                        
                    </Row>                        
                </Container>
            </section>                
        )
    }
}

export default TopSection