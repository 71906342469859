import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import PageText from '../components/PageText'
import PageSubHeading from '../components/PageSubHeading'
import SectionHeading from '../components/SectionHeading'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import {
    fetchMember
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import ProjectItem from '../components/ProjectItem'
import NextPrevNavTeam from '../components/NextPrevNavTeam'
import {Helmet} from "react-helmet";
import {Redirect} from 'react-router-dom'

class Team extends React.Component{

    componentDidMount(){
        this.props.fetchMember(this.props.match.params.searchName)
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.searchName !== this.props.match.params.searchName){
            this.props.fetchMember(this.props.match.params.searchName)
        }
    }

    renderList(){
        return this.props.member.projects.map((item, index) => {
            return(
                <ProjectItem 
                    key={index}
                    image={`${BASE_URL}${item.image}`}
                    link={`/projects/${item.search_name}`}
                    title={item.title}
                />
            )
        })
    }

    render(){
        
        if(!this.props.member){
            return <></>
        }

        if(this.props.member === "Not Found"){
            return <Redirect to="/team" />
        }
        
        const member = this.props.member

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "ImageObject",
                "@id": `https://www.ldbconstruction.com/team/${this.props.match.params.searchName}/#primaryimage`,
                "inLanguage": "en-US",
                "url": BASE_URL+member.image,
                "width": 740,
                "height": 904
              },
              {
                "@type": "WebPage",
                "@id": `https://www.ldbconstruction.com/team/${this.props.match.params.searchName}/#webpage`,
                "url": `https://www.ldbconstruction.com/team/${this.props.match.params.searchName}/`,
                "name": `${member.firstname} | LDB Construction`,
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "primaryImageOfPage": {
                  "@id": `https://www.ldbconstruction.com/team/${this.props.match.params.searchName}/#primaryimage`
                },
                "datePublished": member.created_at,
                "dateModified": member.updated_at,
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                        `https://www.ldbconstruction.com/team/${this.props.match.params.searchName}/`
                    ]
                  }
                ]
              }
            ]
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>{member ? `${member.firstname} - LDB Construction UK Ltd` : ''}</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content={member.meta_description ? member.meta_description : ''}
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    
                    <section className="ldb_member-section">
                        <Container>
                            <Row>
                                <Col sm={12} lg={5}>
                                    <figure>
                                        <img src={`${BASE_URL}${member.image}`} alt={`${member.firstname} ${member.position}`} />
                                        <NextPrevNavTeam 
                                            next={this.props.member.next ? this.props.member.next : null}
                                            prev={this.props.member.previous ? this.props.member.previous : null}
                                        />
                                    </figure>
                                </Col>
                                <Col sm={12} lg={7}>
                                    <PageHeading 
                                        text={`${member.firstname}`}
                                    />
                                    <PageSubHeading 
                                        text={member.position}
                                    />
                                    <PageText 
                                        text={member.bio}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section> 
                    {/* <section className="ldb_associated-products-section">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <SectionHeading 
                                        text="associated projects"
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                {member.projects && this.renderList()}
                            </Row>
                        </Container>
                    </section> */}
                </Nav>       
                <Footer />       
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        member: state.member[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchMember
})(Team)