import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

class AuthLoginForm extends React.Component {

    renderError({error, touched}){
		if(error && touched){
			return(
                <div className="error mt-2" role="alert">
                    {error}
                </div>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        const className = `form-group ${meta.error && meta.touched ? 'in_error' : ''}`

        return (
            <div className={className}>
                <input type={type} className="form-control" {...input} autoComplete="off" placeholder={label} />
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ldb_form">

                <Field name="name" type="text" component={this.renderInput} label="username" />

                <Field name="password" type="password" component={this.renderInput} label="password" />

                <div className="form-group">
                    <button className="ldb_button mt-3">Sign In</button>
                </div>               
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }
    if(!formValues.password){
        errors.password = "You must enter your password"
    }
    
    return errors
}

export default reduxForm({
    form: 'privateUserAuth',
    validate
})(AuthLoginForm)