import React from 'react'
import {Redirect} from 'react-router-dom'

class TeamRedirect extends React.Component{
    render(){

        const searchName = this.props.match.params.searchName

        if (searchName){
            return <Redirect to={`/team/${searchName}`} />
        }
        
        return <Redirect to="/team" />
    }
}

export default TeamRedirect