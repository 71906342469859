import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

import {
    fetchPrivateProjects
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import ProjectItem from '../components/ProjectItem'
import TopSection from '../components/TopSection'
import {Helmet} from "react-helmet";
import AuthLogin from '../components/AuthLogin'
import AuthSignout from '../components/AuthSignout'

class PrivateProjects extends React.Component{

    state = {
        fetched: false
    }

    componentDidMount(){
        if(this.props.auth.token){
            if(!this.state.fetched){
                this.props.fetchPrivateProjects(this.props.auth.token)
                this.setState({
                    fetched: true
                })
            }            
        }
    }

    componentDidUpdate(){
        if(this.props.auth.token){
            if(!this.state.fetched){
                this.props.fetchPrivateProjects(this.props.auth.token)
                this.setState({
                    fetched: true
                })
            } 
        }
    }

    renderList(){
        return this.props.projects.map((item, index) => {
            return (
                <ProjectItem 
                    key={index}
                    image={`${BASE_URL}${item.image}`}
                    link={`/private-projects/${item.search_name}`}
                    title={item.title}
                />
            )
        })
    }

    render(){

        if(!this.props.auth.token){
            return (
                <React.Fragment>
                    <Helmet>
                        
                        <title>Private Projects Login - LDB Construction UK Ltd</title>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                    <Nav> 
                        <TopSection 
                            heading="private projects"
                            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus culpa quas, eos voluptatum aliquam quod ex exercitationem repellat dolorem! Voluptatum iusto ex, repudiandae repellat pariatur porro accusamus ducimus culpa reiciendis."
                        />
                        <section className="ldb_products-section">
                            <Container>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <AuthLogin />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Nav>            
                    <Footer />    
                </React.Fragment>
            )
        }

        if(!this.props.projects) {return <></>}

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Private Projects - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav> 
                    <TopSection 
                        heading="private projects"
                        text="LDB Construction is unique in London in that we offer a complete turnkey service and will work with our clients from initial consultation, to the delivery and installation of furniture, and provide maintenance support after the project is complete. Throughout the project we oversee and manage every detail of the construction and refurbishment so that every inch exactly meets our client’s exacting standards."
                    />
                    <section className="ldb_products-section">
                        <Container>
                            <Row>
                                {this.renderList()}
                            </Row>
                            <Row>
                                <Col sm={12} className="text-center">
                                    <AuthSignout />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>            
                <Footer />    
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.privateProjects,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    fetchPrivateProjects
})(PrivateProjects)