import {
    PRIVATE_USER_LOGIN,
    PRIVATE_USER_LOGOUT,
    PRIVATE_USER_LOGIN_FAILED
} from '../actions/types'

const initialState = {
    token: null,
    message: null
}

export default ( state = initialState, action ) => {
    switch (action.type){
        case PRIVATE_USER_LOGIN:
            return {
                ...state, 
                token: action.payload.data.token,
                message: null
            }
        case PRIVATE_USER_LOGIN_FAILED:
            return {
                ...state,
                message: 'Credentials not found'
            }
        case PRIVATE_USER_LOGOUT:
            return {
                ...state, 
                token: null,
                message: null
            }
        default:
            return state
    }
}