import React from 'react'
import './assets/scss/app.scss'
import {
    BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import history from './history'

//pages
import Landing from './pages/Landing'
import About from './pages/About'
import Team from './pages/Team'
import Member from './pages/Member'
import Aftercare from './pages/Aftercare'
import Contact from './pages/Contact'
import Legal from './pages/Legal'
import Accreditations from './pages/Accreditations'
import Projects from './pages/Projects'
import Project from './pages/Project'
import PageNotFound from './pages/PageNotFound'
import ProjectRedirect from './redirects/ProjectRedirect'
import TeamRedirect from './redirects/TeamRedirect'
import Covid from './pages/Covid'
import PrivateProjects  from './pages/PrivateProjects'
import PrivateProject from './pages/PrivateProject'
import RSVP from './pages/RSVP'
import RSVPThankyou from './pages/RSVPThankyou'
import Sustainability from './pages/Sustainability'

class App extends React.Component{

    state = {
        hide: true
    }

    componentDidMount(){
        this.showContent()
    }

    showContent = () => {
        setTimeout(() => {
            this.setState({hide: false})
        }, 1000);
        
    }

    render(){
        return(
            <div className={`_wrapper ${this.state.hide ? '_hide' : ''}`}>
                <Router history={history}>
                    <ScrollToTop />
                    <Switch>                    
                        <Route exact path="/" component={Landing} />
                        <Route exact path="/projects" component={Projects} />
                        <Route exact path="/projects/:searchName" component={Project} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/team" component={Team} />
                        <Route exact path="/team/:searchName" component={Member} />
                        <Route exact path="/aftercare" component={Aftercare} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/legal" component={Legal} />
                        <Route exact path="/accreditations" component={Accreditations} />
                        <Route exact path="/page-not-found" component={PageNotFound} />
                        <Route exact path="/private-projects" component={PrivateProjects} />
                        <Route exact path="/year-end-party-2021-rsvp" component={RSVP} />
                        <Route exact path="/year-end-party-2021-rsvp/thankyou" component={RSVPThankyou} />
                        <Route exact path="/private-projects/:searchName" component={PrivateProject} />
                        <Route exact path="/sustainability" component={Sustainability} />

                        {/* Redirects from the old site url's */}
                        <Route exact path="/construction/overview/">
                            <Redirect to={{ pathname: "/" }} />
                        </Route>
                        <Route exact path="/ethos/background/">
                            <Redirect to={{ pathname: "/about" }} />
                        </Route>
                        <Route exact path="/maintenance/">
                            <Redirect to={{ pathname: "/" }} />
                        </Route>
                        <Route exact path="/portfolio">
                            <Redirect to={{ pathname: "/projects" }} />
                        </Route>
                        <Route exact path="/team/profiles/">
                            <Redirect to={{ pathname: "/team" }} />
                        </Route>
                        <Route exact path="/us/health-safety/">
                            <Redirect to={{ pathname: "/about" }} />
                        </Route>
                        <Route exact path="/news">
                            <Redirect to={{ pathname: "/projects" }} />
                        </Route>
                        <Route exact path="/covid19/">
                            <Redirect to={{ pathname: "/wellness" }} />
                        </Route>
                        <Route exact path="/us/accreditations/">
                            <Redirect to={{ pathname: "/accreditations/" }} />
                        </Route>
                        <Route exact path="/us/social-responsibility/">
                            <Redirect to={{ pathname: "/" }} />
                        </Route>                    
                        <Route exact path="/portfolio/:searchName" component={ProjectRedirect} />
                        <Route exact path="/archives/:searchName" component={ProjectRedirect} />
                        <Route exact path="/team/profiles/:searchName" component={TeamRedirect} />

                        <Route exact path="/wellness" component={Covid} />

                        {/* Page Not Found */}
                        <Route>
                            <Redirect to={{ pathname: "/page-not-found" }} />
                        </Route>                    
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default App