import React from 'react'

class PageSubHeading extends React.Component{
    render(){
        return(
            <h4 className="ldb_page-subheading">{this.props.text}</h4>
        )
    }
}

export default PageSubHeading