export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const FETCH_TEAM = 'FETCH_TEAM'
export const FETCH_MEMBER = 'FETCH_MEMBER'
export const PRIVATE_USER_LOGIN = 'PRIVATE_USER_LOGIN'
export const PRIVATE_USER_LOGIN_FAILED = 'PRIVATE_USER_LOGIN_FAILED'
export const PRIVATE_USER_LOGOUT = 'PRIVATE_USER_LOGOUT'
export const FETCH_PRIVATE_PROJECTS = 'FETCH_PRIVATE_PROJECTS'
export const FETCH_PRIVATE_PROJECT = 'FETCH_PRIVATE_PROJECT'
export const RSVP_SUBMIT = 'RSVP_SUBMIT'
export const RSVP_SUBMIT_CLEAR = 'RSVP_SUBMIT_CLEAR'