import {
    FETCH_MEMBER
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case FETCH_MEMBER: 
            return {
                ...state,
                [action.payload.searchName]: action.payload.data
            }
        default:
            return state
    }
}