import React from 'react'
import ReactDOM from 'react-dom'

class Popup extends React.Component {

    render(){
        return(
            ReactDOM.createPortal(
                <div className="ldb_popup-wrap">
                    <div className="ldb_overlay" onClick={this.props.onClick}></div>
                    <button className="ldb_close-popup" title="Close Popup" onClick={this.props.onClick}><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg></button>
                    <div className="ldb_popup-inner">
                        {this.props.children}
                    </div>
                </div>, 
                document.querySelector("#ldb_popup")
            )
        )
    }
}

export default Popup