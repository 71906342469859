import React from 'react'

class PageHeading extends React.Component{
    render(){
        return(
            <h1 className="ldb_page-heading">{this.props.text}</h1>
        )
    }
}

export default PageHeading