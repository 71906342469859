import React from 'react'
import {Redirect} from 'react-router-dom'

class ProjectRedirect extends React.Component{
    render(){

        const searchName = this.props.match.params.searchName

        if (searchName){
            return <Redirect to={`/projects/${searchName}`} />
        }
        
        return <Redirect to="/projects" />
    }
}

export default ProjectRedirect