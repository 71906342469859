import React from 'react'
import {
    Link
} from 'react-router-dom'

class NextPrevNavTeam extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="ldb_next-prev-nav-team">
                    {
                        this.props.prev && (
                            <Link className="ldb_inner ldb_prev" to={this.props.prev.search_name} title={this.props.prev.firstname + ' ' + this.props.prev.lastname}><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg> Prev</Link>
                        )
                    }                                        
                    {
                        this.props.next && (
                            <Link className="ldb_inner ldb_next" to={this.props.next.search_name} title={this.props.next.firstname + ' ' + this.props.next.lastname}>Next <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></Link>
                        )
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default NextPrevNavTeam