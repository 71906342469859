import React from 'react'
import NavLinks from './NavLinks'

class WidescreenNav extends React.Component{
    render(){
        return(
            <ul className="ldb_list-widescreen-menu">
                <NavLinks />
            </ul>
        )
    }
}

export default WidescreenNav