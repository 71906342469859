import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import {Helmet} from "react-helmet";
import Footer from '../components/Footer'

class PageNotFound extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>404 Page Not Found - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="noindex, nofollow" />
                    <meta
                        name="description"
                        content="Sorry, we couldn't find what you where looking for."
                    />
                </Helmet>
                <Nav>                    
                    <TopSection 
                        heading="404 Page Not Found"
                        text="Sorry, we couldn't find what you where looking for."
                    />
                </Nav>   
                <Footer />             
            </React.Fragment>
        )
    }
}

export default PageNotFound