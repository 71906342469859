import React from 'react'
import Nav from '../components/Nav'
import {Helmet} from "react-helmet"
import Footer from '../components/Footer'
import TopSection from '../components/TopSection'
import {Link}  from 'react-router-dom'

class RSVPThankyou extends React.Component{

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Year End Part RSVP Thankyou - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav>
                    <TopSection 
                        heading="Thank You"
                        text="Looking forward to seeing you there."
                    >
                    </TopSection>
                </Nav>   
                <Footer />             
            </React.Fragment>
        )
    }
}

export default RSVPThankyou